.container{
    padding: 0rem 2rem;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}
.wrapper{
    display:flex;
    align-items: basline;
    position:relative;
    justify-content: center;
}

.wrapper>img{
    width: 30rem;
    position: absolute;
    bottom:0;
}

.blueCircle{
    width:30rem;
    height: 30rem;
    border-radius: 50%;
    z-index:-99;
    position: absolute;
    background: rgb(100, 100, 250);
 
   
}


.cart2{
    position:absolute;
    bottom: 25%;
    left: 120%;
    gap:1rem;
    align-items: center;
    display: flex;
}

.cart2>svg{
    width: 30px;
    height: 30px;
    background: white;
    padding:10px;
    border-radius:50%;
    border: 6px solid var(--black)

}

.signup{
    display:flex;
    gap:1rem;
    align-items: center;
    background: white;
    padding: 10px;
    font-size: 0.8rem;
    border-radius: 15px;
    box-shadow: var(--shadow1);
}

.signup>:first-child{
    display:block;
    width: 6rem;
}

.h_sides{
    display: grid;
    grid-template-rows: 1fr 1 fr;
    vertical-align: middle;
}

.text1{
    text-transform: uppercase;
    font-size: 1.5rem;
    display: block;
    width: min-content;
    font-weight: 600;
}

.text2{
    display: flex;
    flex-direction: column;
    width: min-content;
    color: black;
   
    
}

.text2>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
}

.text2>nth-child(2){
    display:block;
}

.traffic{
    display: flex;
    flex-direction: column;
    text-align: right;
}

.traffic>:first-child{
    font-weight: 800;
    font-size:2.5rem;
}

.customers{
    display:flex;
    flex-direction: column;
    text-align: right;
}

.customers>:first-child{
    font-weight: 800;
    text-align: right;
    font-size: 2.5rem;
}

.h_sides > :nth-child(2) {
    margin-top: 12rem; /* Ajusta el valor según sea necesario */
  }

  @media screen and (max-width: 640px) {
    .blueCircle{
        top: 10%;
    width: 40vw;
    height: 80vw;
    max-width: 10rem;
    max-height: 0
    
    }
    .container {
        grid-template-columns: 1fr;
      }

    .text2{
        color: white;
    }

    .wrapper {
        display: none;
      }

    .wrapper>img{
        top:3rem;
        width: 12 rem;
        height: 15rem;

    }
  
    .cart2{
        transform: scale(.5);
       display: none;
        right: -2rem;
    }

  }

  .customers{
    display: none;
  }